export const AUTH_API = {
  LOGIN: "token",
  REGISTER: "user/register",
  CHANGE_PASSWORD: "user/change-password",
  FORGET_PASSWORD: "user/checking-username-and-otp/",
  REFRESH_TOKEN: "token/refresh",
  BLACKLIST_TOKEN: "user/blacklist-refresh",

  // New APIS
  LOGIN_USER: "user/login",
  VERIFY_USER: "user/verify-otp",
  FORGET_PASSWORD_OTP: "user/forget-password-otp",
  VERIFY_FORGET_PASSWORD: "user/forget-password-otp-verify",
  RESET_PASSWORD: "user/reset-password",
} as const;
