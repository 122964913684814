export const ADMIN_APIS = {
  USER_LIST: "user/",
  TRACE_LOG: "trace_log/users",
  USER_CREATE: "user/staff/create",
  USER_POLICY: "user/password-policy",
  USER_CHANGE_PASSWORD: "user/change-user-password/",
  ACTIVITY_PRODUCTS_UPDATE: "lookup/activity/product/create",
  USER_INTERNAL_DATA: "customer/approved/get-for-update/",
  USER_INTERNAL_DATA_UPDATE: "customer/approved/update/",
  PRODUCTS_HISTORY_REPORT: "customer/products/history",
  PRODUCTS_HISTORY_REPORT_EXCEL: "customer/products/history/export",
  TEMP_AMOUNT_HISTORY_REPORT: "customer/temp-products/history",
  TEMP_AMOUNT_HISTORY_REPORT_EXCEL: "customer/temp-products/history/export",
  ACTIVATE_CLIENT: "customer/mark-not-deleted/",
  DEACTIVATE_CLIENT: "customer/mark-deleted/",
  RESET_PASSWORD_POLICY: "user/reset-password-policy",
  GET_CURRENT_PASSWORD_POLICY: "user/password-policy",
} as const;
