// Angular modules
import { Component, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";

// Models
import { ResponseDto } from "src/app/core/interfaces/api-response";
import { customer_category } from "src/app/core/utilities/shared_enums";
import { FilterModelDto } from "../../company/company.utility";

// Services
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "src/app/core/services/api.service";
import { DialogService } from "src/app/core/services/dialog.service";
import { SecurityService } from "src/app/core/services/security.service";
import { AdminService } from "../../admin/admin.service";

import { COMPANY_APIS } from "src/app/core/apis/company.api";
import { CUSTOMER_APIS } from "src/app/core/apis/customer.api";
import { LOOKUPS_API } from "src/app/core/apis/lookup.api";

import { BehaviorSubject, forkJoin } from "rxjs";

import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ADMIN_APIS } from "src/app/core/apis/admin.api";

@Component({
  selector: "app-customer-search",
  templateUrl: "./customer-search.component.html",
  styleUrls: ["./customer-search.component.scss"],
})
export class CustomerSearchComponent implements OnInit {
  // #Region variables
  activeCustomerType: string;
  userType: string;
  title: string;
  hasData$: BehaviorSubject<Boolean> = new BehaviorSubject(false);

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  clientsDataSource: MatTableDataSource<any>;
  displayedColumns: string[];
  pageSize = 25;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  // list group
  ApprovedClientsExcelData: { flour: number; type: string }[];
  allBranches: any[];

  CATEGORY = customer_category;

  // models
  filterModel: FilterModelDto;

  // #endregion

  // #Region Constructor

  constructor(
    private securityService: SecurityService,
    private translation: TranslateService,
    private activeRouter: ActivatedRoute,
    private dialogService: DialogService,
    private adminService: AdminService,
    private titleService: Title,
    private API: ApiService
  ) {
    // general settings
    this.clientsDataSource = new MatTableDataSource();

    this.userType = this.securityService.retrieveUser().user_type;

    // init variables
    this.filterModel = new FilterModelDto();

    this.initApprovedClients();
    this.displayedColumns = [
      "name",
      "customer_category_name",
      "branch_name",
      "sap_customer_id",
      "sago_customer_id",
      "customer_number",
      "is_deleted",
      "actions",
    ];
  }

  // #endregion

  // #Region OnInit

  ngOnInit(): void {
    this.initClientType();
    this.loadControls();
  }

  ngAfterViewInit() {
    this.clientsDataSource.paginator = this.paginator;
    this.clientsDataSource.sort = this.sort;
  }

  // #endregion

  initClientType() {
    this.activeRouter.params.subscribe((urlSegment) => {
      this.activeCustomerType = urlSegment.type;
      if (this.activeCustomerType == "flour") {
        this.title = "SEARCH_CUSTOMERS";
        this.titleService.setTitle(`${this.translation.instant(this.title)}`);
        this.getApprovedFlourClients();
      } else {
        this.title = "FEED_SEARCH_CUSTOMERS";
        this.titleService.setTitle(`${this.translation.instant(this.title)}`);
        this.getApprovedFeedClients();
      }
    });
  }

  // #region load controls

  loadControls() {
    this.getAllBranches();
  }

  getAllBranches() {
    this.API.getDataFromServer(LOOKUPS_API.BRANCHES).subscribe(
      (response: ResponseDto) => (this.allBranches = response.data)
    );
  }

  // #endregion

  // #Region Excel sheets download

  initApprovedClients() {
    this.ApprovedClientsExcelData = [
      { flour: 1, type: "عملاء الدقيق المباشر" },
      { flour: 2, type: "موزعون الدقيق" },
      { flour: 3, type: "عملاء الدقيق متعددي الفروع" },
      { flour: 4, type: "عملاء الدقيق المستفيدون" },
    ];
  }

  downloadExcelSheetTemplate(
    clientsMetaData: { flour: number; type: string }[]
  ) {
    clientsMetaData.forEach((client) => {
      this.adminService
        .exportApprovedFlourClients(client.flour)
        .subscribe((res) => {
          this.downloadFile(res, client.type);
        });
    });
  }

  downloadCustomersProducts() {
    this.adminService
      .exportApprovedFlourClientsProducts()
      .subscribe((file: any) => {
        this.downloadFile(file, "كميات منتجات عملاء الدقيق");
      });
  }

  downloadSagoCustomers() {
    const clients = this.adminService.exportSagoClients();
    const products = this.adminService.exportSagoProducts();
    forkJoin([clients, products]).subscribe((sheets) => {
      this.downloadFile(sheets[0], "العملاء");
      this.downloadFile(sheets[1], "المنتجات");
    });
  }

  downloadFile(blob: any, fileName: string) {
    const url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    anchor.download = `${fileName}.xlsx`;
    anchor.href = url;
    anchor.click();
  }

  // #endregion

  // #Region Clients-Search

  getApprovedFlourClients() {
    this.API.getDataFromServer(
      COMPANY_APIS.APPROVED_LIST,
      { page: this.currentPage + 1, size: this.pageSize },
      this.filterModel
    ).subscribe((res) => {
      this.clientsDataSource.data = res.data;
      setTimeout(() => {
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = res.count;
      });

      if (res.count > 0) this.hasData$.next(true);
      else this.hasData$.next(false);
    });
  }

  getApprovedFeedClients() {
    this.API.getDataFromServer(
      COMPANY_APIS.APPROVED_FEED_LIST,
      { page: this.currentPage + 1, size: this.pageSize },
      this.filterModel
    ).subscribe((res) => {
      this.clientsDataSource.data = res.data;
      setTimeout(() => {
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = res.count;
      });

      if (res.count > 0) this.hasData$.next(true);
      else this.hasData$.next(false);
    });
  }

  pageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getApprovedFlourClients();
  }

  // #endregion

  // #region filter section

  newFilterModel(model: FilterModelDto) {
    this.filterModel = model;
    this.getApprovedFlourClients();
  }

  // #endregion

  //#region table actions

  activateClient(customer_id: string) {
    this.API.updateDataOnServer(
      "patch",
      ADMIN_APIS.ACTIVATE_CLIENT,
      {},
      customer_id
    ).subscribe(() => {
      this.dialogService.savedSuccessfullyWithMessage(
        "USER_ACTIVATED_SUCCESSFULLY"
      );
      this.getApprovedFlourClients();
    });
  }

  deActivateClient(customer_id: string) {
    this.API.updateDataOnServer(
      "patch",
      ADMIN_APIS.DEACTIVATE_CLIENT,
      {},
      customer_id
    ).subscribe(() => {
      this.dialogService.savedSuccessfullyWithMessage(
        "USER_DEACTIVATED_SUCCESSFULLY"
      );
      this.getApprovedFlourClients();
    });
  }

  changeSapNumber(customer_id: number) {
    let model = { sap_customer_id: 0 };
    this.dialogService
      .changeCustomerInfoRequest(
        "تغيير رقم ساب المسجل للعميل",
        "برجاء استخدام رقم ساب صحيح و لا يقل عن 20 رقم"
      )
      .then((res) => {
        if (res.isConfirmed) {
          model.sap_customer_id = res.value;
          this.changeCustomerSapNumber(customer_id, model);
        }
      });
  }

  changeCustomerSapNumber(customer_id: number, model: object) {
    this.API.updateDataOnServer(
      "put",
      CUSTOMER_APIS.SAP_NUMBER_UPDATE + customer_id,
      model
    ).subscribe(() => {
      this.dialogService.savedSuccessfully();
    });
  }

  // #endregion
}
