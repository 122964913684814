<ng-container *ngIf="clientData$ | async as client; else loader">
  <div class="card rounded mx-2 p-4">
    <div class="row">
      <ng-container *ngIf="client.distributer_name">
        <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
          <span class="bold">{{ "RELATED_TO" | translate }} :</span> :
          {{ client.distributer_name || "__" | translate }}
        </div>
        <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
          <span class="bold">{{ "DISTRIBUTER_SAGO_NUM" | translate }}</span> :
          {{ client.distributer_sago_customer_id || "__" | translate }}
        </div>
        <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
          <span class="bold">{{ "DISTRIBUTER_SAP_NUM" | translate }}</span> :
          {{ client.distributer_sap_customer_id || "__" | translate }}
        </div>
        <hr class="w-75 my-3 mx-auto" />
      </ng-container>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "CLIENT_PORTAL_NUM" | translate }}</span> :
        {{ client.customer_number || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "CLIENT_SAGO_NUM" | translate }}</span> :
        {{ client.sago_customer_id || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "CLIENT_SAP_NUM" | translate }}</span> :
        {{ client.sap_customer_id || "__" }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "ORG_NAME" | translate }}</span> :
        {{ client.name || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "TRADE_NAME" | translate }}</span> :
        {{ client.tradeName || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "UNIFIED_NUM" | translate }}</span> :
        {{ client.unifiedNumber || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "ID_NUM" | translate }}</span> :
        {{ client.id_number || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "CLIENT_CATEGORY" | translate }}</span> :
        {{
          (client.customer_category === CATEGORY.DIRECT && CATEGORY.direct) ||
            (client.customer_category === CATEGORY.DISTRIBUTER &&
              CATEGORY.distributer) ||
            (client.customer_category === CATEGORY.MULTI && CATEGORY.multi) ||
            (client.customer_category === CATEGORY.BENEFICIARY &&
              CATEGORY.beneficiary) ||
            ""
        }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "CITY" | translate }}</span> :
        {{ client.city_name || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "AREA" | translate }}</span> :
        {{ client.region_name || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "DISTRICT" | translate }}</span> :
        {{ client.district_name || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "BRANCH" | translate }}</span> :
        {{ client.branch_name || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "MOBILE_NUM" | translate }}</span> :
        {{ client.mobile || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "PHONE_NUM" | translate }}</span> :
        {{ client.phone || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "ADDRESS" | translate }}</span> :
        {{ client.address || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "STREET_NAME" | translate }}</span> :
        {{ client.street || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "FAX_NUM" | translate }}</span> :
        {{ client.fax || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "ACTIVITY_TYPE" | translate }}</span> :
        {{ client.activity_type_name || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "COMMERCIAL_NUM" | translate }}</span> :
        {{ client.commercial_registration_number || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "COMMERCIAL_END_DATE" | translate }}</span> :
        {{ client.commercial_registration_expiry_date || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "LICENCE_NUM" | translate }}</span> :
        {{ client.city_license_number || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "LICENCE_ORIGIN" | translate }}</span> :
        {{ client.city_license_source || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "LICENCE_END_DATE" | translate }}</span> :
        {{ client.city_license_expiration_date || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "INDUSTRIAL_NUM" | translate }}</span> :
        {{ client.industrial_registration_number || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "INDUSTRIAL_END_DATE" | translate }}</span> :
        {{ client.industrial_registration_expiry_date || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "POSTAL_CODE" | translate }}</span> :
        {{ client.postal_code || "__" | translate }}
      </div>
      <div class="col-12 col-md-6 col-lg-4 my-2 custom-right-border">
        <span class="bold">{{ "TAX_NUM" | translate }}</span> :
        {{ client.tax_registeration_number || "__" | translate }}
      </div>
      <div
        *ngIf="approved"
        class="col-12 col-md-6 col-lg-4 my-2 custom-right-border"
      >
        <span class="bold">{{ "OWNER_NOTES" | translate }}</span> :
        {{ client.notes ? client.notes : ("NO_VALUE" | translate) }}
      </div>
      <hr class="my-3 mx-auto" />
      <div class="row">
        <a
          *ngIf="client?.commercial_register_copy"
          target="_blank"
          [href]="client.commercial_register_copy"
          class="col-12 col-md-4 col-lg-3 my-2 custom-right-border"
        >
          {{ "COMMERCIAL_COPY" | translate }}
        </a>
        <a
          *ngIf="client?.city_license_copy"
          target="_blank"
          [href]="client.city_license_copy"
          class="col-12 col-md-4 col-lg-3 my-2 custom-right-border"
        >
          {{ "LICENCE_COPY" | translate }}
        </a>
        <a
          *ngIf="client?.industrial_registration_copy"
          target="_blank"
          [href]="client.industrial_registration_copy"
          class="col-12 col-md-4 col-lg-3 my-2 custom-right-border"
        >
          {{ "INDUSTRIAL_COPY" | translate }}
        </a>
        <a
          *ngIf="client?.tax_registeration_copy"
          target="_blank"
          [href]="client.tax_registeration_copy"
          class="col-12 col-md-4 col-lg-3 my-2 custom-right-border"
        >
          {{ "TAX_COPY" | translate }}
        </a>
        <a
          *ngIf="client?.address_copy"
          target="_blank"
          [href]="client.address_copy"
          class="col-12 col-md-4 col-lg-3 my-2 custom-right-border"
        >
          {{ "ADDRESS_COPY" | translate }}
        </a>
      </div>
    </div>
    <div *ngIf="isProfile">
      <h2 class="m-0">{{ "PRODUCTS" | translate }}</h2>
      <app-advancedtable
        [headers]="listOfHeaderTitle"
        [list]="client.approved_customer_products"
        [hasPagination]="false"
      ></app-advancedtable>
    </div>
  </div>
</ng-container>

<ng-template #loader>
  <div class="text-center">
    <div
      class="spinner-border text-info"
      style="width: 2rem; height: 2rem"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
