<div id="layout-wrapper">
  <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()"> </app-topbar>

  <app-sidebar [isCondensed]="isCondensed"></app-sidebar>

  <!-- content -->
  <div class="main-content">
    <router-outlet></router-outlet>
  </div>
  <!-- footer -->
  <app-footer class="w-100"></app-footer>
</div>
