import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ApiService } from "src/app/core/services/api.service";
import { LOOKUPS_API } from "src/app/core/apis/lookup.api";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";

// #endregion

@Injectable({
  providedIn: "root",
})
export class AdminService {
  // #region constructor
  constructor(private http: HttpClient, private API: ApiService) {}

  selectedRow: any;
  productsList: BehaviorSubject<any> = new BehaviorSubject([]);
  products: Observable<any> = this.productsList.asObservable();
  isProductsUpdated: boolean = true;

  checkProductExist(code: string) {
    return of(this.productsList.value.find((prod) => prod.code == code));
  }

  getProductsList() {
    this.API.getDataFromServer(LOOKUPS_API.ADMIN_PRODUCTS)
      .pipe(
        map((data: any) => {
          return data.map((product) => {
            return {
              ...product,
              active: product.is_active ? "ACTIVE" : "INACTIVE",
              supported: product.is_sago_restricted
                ? "REGISTERED"
                : "NON_REGISTERED",
            };
          });
        })
      )
      .subscribe((res) => this.productsList.next(res));
  }

  // #region main actions

  // #Region customer Excel sheets

  exportApprovedFlourClients(count: number) {
    const url = `customer/approved/flour${count}/export`;
    return this.http.get(url, { responseType: "blob" });
  }

  exportApprovedFlourClientsProducts() {
    const url = `customer/approved/products/export`;
    return this.http.get(url, { responseType: "blob" });
  }

  exportSagoProducts() {
    const url = `customer/approved/products/sago/export`;
    return this.http.get(url, { responseType: "blob" });
  }

  exportSagoClients() {
    const url = `customer/approved/sago/export`;
    return this.http.get(url, { responseType: "blob" });
  }
}
