// angular
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { formApprovalDto } from "src/app/pages/company/company.utility";

// sweetalert
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(private router: Router, private translation: TranslateService) {}

  confirmDelete() {
    return Swal.fire({
      title: this.translation.instant("YOU_SURE_DELETE"),
      text: this.translation.instant("NO_GOING_BACK"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: this.translation.instant("CANCEL"),
      confirmButtonText: this.translation.instant("CONFIRM_SAVE"),
    });
  }

  confirmInfo(title: string, text: string) {
    return Swal.fire({
      title: this.translation.instant(title),
      text: this.translation.instant(text),
      icon: "info",
      showCancelButton: true,
      cancelButtonText: this.translation.instant("CANCEL"),
      confirmButtonColor: "#3085d6",
      confirmButtonText: this.translation.instant("OKY"),
    });
  }

  successAndRouteBack(route: string) {
    Swal.fire({
      position: "center",
      icon: "success",
      title: this.translation.instant("SAVED_SUCCESSFULLY"),
      showConfirmButton: false,
      timer: 1500,
    });
    this.router.navigate([route]);
  }

  savedSuccessfully() {
    Swal.fire({
      icon: "success",
      title: this.translation.instant("SAVED_SUCCESSFULLY"),
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });
  }

  savedSuccessfullyWithMessage(message: string) {
    Swal.fire({
      icon: "success",
      title: this.translation.instant(message),
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });
  }

  savedSuccessfullyWithReq(request_number: string | number) {
    return Swal.fire({
      title:
        this.translation.instant("SAVED_SUCCESSFULLY_WITH_REQ") +
        request_number,
      icon: "info",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: this.translation.instant("OKY"),
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }

  successAndRouteToWithReq(request_number: string | number, route: string) {
    Swal.fire({
      title:
        this.translation.instant("SAVED_SUCCESSFULLY_WITH_REQ") +
        request_number,
      icon: "info",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: this.translation.instant("OKY"),
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    this.router.navigate([route]);
  }

  successAndRouteTo(route: string) {
    Swal.fire({
      position: "center",
      icon: "success",
      title: this.translation.instant("SAVED_SUCCESSFULLY"),
      showConfirmButton: false,
      timer: 2000,
    });
    this.router.navigate([route]);
  }

  alertMessage(title: string) {
    Swal.fire({
      icon: "warning",
      title: this.translation.instant(title),
      confirmButtonColor: "#3085d6",
      confirmButtonText: this.translation.instant("OKY"),
    });
  }

  alertMessageAndRouteTo(title: string, route: string) {
    Swal.fire({
      icon: "warning",
      title: this.translation.instant(title),
      timer: 2000,
    });
    this.router.navigate([route]);
  }

  confirmSavedData(text?: string) {
    return Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      title: this.translation.instant("SAVED_SUCCESSFULLY"),
      text,
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: this.translation.instant("OKY"),
    });
  }

  confirmSavingData(text?: string) {
    return Swal.fire({
      title: this.translation.instant("SURE_SAVE_ACCEPT"),
      text: this.translation.instant(text),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: this.translation.instant("OKY"),
      cancelButtonText: this.translation.instant("CANCEL"),
    });
  }

  confirmActionWithMessage(
    title: string,
    label: string,
    model: formApprovalDto
  ) {
    return Swal.fire({
      inputPlaceholder: this.translation.instant(label),
      title: this.translation.instant(title),
      input: "text",
      showCancelButton: true,
      confirmButtonText: this.translation.instant("OKY"),
      cancelButtonText: this.translation.instant("CANCEL"),
      inputValidator: (value) => {
        return new Promise((resolve): void => {
          if (value && value.length > 10) {
            resolve("");
            if (!model.is_approved)
              if (model.is_request_for_info)
                resolve((model.request_for_info_comment = value));
              else resolve((model.reject_reason = value));
          } else {
            resolve(this.translation.instant("WRITE_REASON"));
          }
        });
      },
    });
  }

  changeCustomerInfoRequest(title_message: string, warning_message: string) {
    return Swal.fire({
      title: title_message,
      input: "number",
      showCancelButton: true,
      confirmButtonText: this.translation.instant("OKY"),
      cancelButtonText: this.translation.instant("CANCEL"),
      customClass: { input: "bg-light" },
      reverseButtons: true,
      inputValidator: (value) => {
        return new Promise((resolve): void => {
          if (value && value.length >= 20) {
            resolve("");
            resolve(value);
          } else resolve(warning_message);
        });
      },
    });
  }
}
