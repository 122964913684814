import { MenuItem } from "./menu.model";

export const Admin_Menu: MenuItem[] = [
  {
    id: 1,
    label: "REQUESTS",
    icon: "bx-file",
    subItems: [
      {
        id: 101,
        label: "MILLING_CO",
        icon: "bx-dots-horizontal",
        parentId: 1,
        subItems: [
          {
            id: 10101,
            label: "COMPANY_NEW",
            link: "/pages/admin/request/list/new",
            parentId: 101,
          },
          {
            id: 10102,
            label: "JOIN_REQUESTS",
            link: "/pages/company/join-request/list/flour",
            parentId: 101,
          },
          {
            id: 10103,
            label: "JOIN_REQUESTS_feed",
            link: "/pages/company/join-request/list/feed",
            parentId: 101,
          },
          {
            id: 10104,
            label: "PENDING_REQS",
            link: "/pages/admin/request/list/company-pending",
            parentId: 101,
          },
          {
            id: 10105,
            label: "APPROVED_REQS",
            link: "/pages/admin/request/list/approved",
            parentId: 103,
          },
          {
            id: 10106,
            label: "REJECTED_REQS",
            link: "/pages/admin/request/list/company-rejected",
            parentId: 101,
          },
        ],
      },
      {
        id: 102,
        label: "CUSTOMER_REQUESTS",
        icon: "bx-dots-horizontal",
        parentId: 2,
        subItems: [
          {
            id: 10201,
            label: "INCREASE_REQUESTS",
            link: "/pages/customer/increase-amount/list/flour",
            parentId: 102,
          },
          {
            id: 10202,
            label: "TEMP_REQUESTS",
            link: "/pages/customer/temporary-product-manager-list",
            parentId: 102,
          },
          {
            id: 10203,
            label: "CHANGE_INFO_REQS",
            link: "/pages/customer/edit_profile/list",
            parentId: 102,
          },
          {
            id: 10204,
            label: "CHANGE_CATEGORY_REQS",
            link: "/pages/customer/change_category/list",
            parentId: 102,
          },
          {
            id: 10205,
            label: "CHANGE_PRODUCTS_REQS",
            link: "/pages/customer/edit_product/list",
            parentId: 102,
          },
          {
            id: 10206,
            label: "DISTRIBUTER_T_DISTRIBUTER",
            link: "/pages/customer/change-distributer-list",
            parentId: 102,
          },
          {
            id: 10207,
            label: "BRANCH_T_BRANCH",
            link: "/pages/customer/branch-transfer/list",
            parentId: 102,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    label: "SAGO",
    icon: "bx-cube",
    subItems: [
      {
        id: 201,
        label: "SAGO_APPROVED",
        link: "/pages/admin/request/list/approved",
        parentId: 2,
      },
      {
        id: 201,
        label: "SAGO_SENT",
        link: "/pages/admin/request/list/sago-sent",
        parentId: 2,
      },
      {
        id: 203,
        label: "SAGO_PENDING",
        link: "/pages/admin/request/list/sago-pending",
        parentId: 2,
      },
      {
        id: 204,
        label: "COMPANY_TRANSFERS",
        link: "/pages/admin/request/list/company-transfer",
        parentId: 2,
      },
      {
        id: 205,
        label: "SAGO_REJECTED",
        link: "/pages/admin/request/list/sago-rejected",
        parentId: 2,
      },
    ],
  },
  {
    id: 3,
    label: "CUSTOMER_SEARCH",
    icon: "bx-group",
    subItems: [
      {
        id: 301,
        label: "SEARCH_CUSTOMERS",
        link: "/pages/admin/customer-search/flour",
        parentId: 3,
      },
      {
        id: 302,
        label: "FEED_SEARCH_CUSTOMERS",
        link: "/pages/admin/customer-search/feed",
        parentId: 3,
      },
    ],
  },
  {
    id: 4,
    label: "USERS",
    icon: "bx-user",
    subItems: [
      {
        id: 401,
        label: "USERS_LIST",
        link: "/pages/admin/user/list",
        parentId: 4,
      },
      {
        id: 402,
        label: "USERS_ADD",
        link: "/pages/admin/user/add",
        parentId: 4,
      },
      {
        id: 403,
        label: "CUSTOMERS_LIST",
        link: "/pages/admin/customers/list",
        parentId: 4,
      },
    ],
  },
  {
    id: 5,
    label: "SETTINGS",
    icon: "bx-cog",
    subItems: [
      {
        id: 501,
        label: "SETTINGS_ACTIVITIES",
        link: "/pages/admin/settings/activity_type/list",
        parentId: 5,
      },
      {
        id: 502,
        label: "SETTINGS_PRODUCTS",
        link: "/pages/admin/settings/products/list",
        parentId: 5,
      },
      {
        id: 503,
        label: "PASSWORD_POLICY",
        link: "/pages/admin/settings/password-policy",
        parentId: 5,
      },
      {
        id: 504,
        label: "DATA_SYNCRONIZATION",
        link: "/pages/admin/settings/data-sync-runner",
      },
    ],
  },
  {
    id: 6,
    label: "GFSA_INSTITUTE",
    icon: "bxs-buildings",
    subItems: [
      {
        id: 601,
        label: "DELETED_INQUIRY",
        link: "/pages/company/sago-removed-clients",
        parentId: 6,
      },
      {
        id: 602,
        label: "THOUSAND_BAGS",
        link: "/pages/customer/more-than-thousand-bag/list",
        parentId: 4,
      },
    ],
  },
  {
    id: 7,
    label: "REPORTS",
    icon: "bxs-report",
    subItems: [
      {
        id: 701,
        label: "PRODUCTS_HISTORY",
        link: "/pages/admin/reports/product-history",
        parentId: 7,
      },
      {
        id: 702,
        label: "TEMP_AMOUNT_HISTORY",
        link: "/pages/admin/reports/temp-amount-history",
        parentId: 7,
      },
    ],
  },
  // {
  //   id: 8,
  //   label: "UPLOAD_CUSTOMERS",
  //   icon: "bxs-cloud-upload",
  //   subItems: [
  //     {
  //       id: 801,
  //       label: "UPLOAD_CUSTOMERS_FLOUR",
  //       link: "/pages/admin/upload/flour/customers",
  //       parentId: 8,
  //     },
  //     {
  //       id: 802,
  //       label: "UPLOAD_FLOUR_BENEFICIARY",
  //       link: "/pages/admin/upload/flour/beneficiary",
  //       parentId: 8,
  //     },
  //     {
  //       id: 803,
  //       label: "UPLOAD_CUSTOMERS_FEED",
  //       link: "/pages/admin/upload/feed/customers",
  //       parentId: 8,
  //     },
  //   ],
  // },
  // {
  //   id: 9,
  //   label: "UPLOAD_CUSTOMER_PRODUCTS",
  //   icon: "bx-upload",
  //   subItems: [
  //     {
  //       id: 901,
  //       label: "DOWNLOAD_MODEL",
  //       link: "/https://crm.firstmills.com/milling/media/templates/ApprovedCustomerProductTemplate.xlsx",
  //       parentId: 9,
  //     },
  //     {
  //       id: 902,
  //       label: "FLOUR_PRODUCTS",
  //       link: "/pages/admin/upload/flour/products",
  //       parentId: 9,
  //     },
  //     {
  //       id: 903,
  //       label: "FEED_PRODUCTS",
  //       link: "/pages/admin/upload/feed/products",
  //       parentId: 9,
  //     },
  //   ],
  // },
  {
    id: 10,
    label: "SAP_INTEGRATION",
    icon: "bxs-buildings",
    subItems: [
      {
        id: 1001,
        label: "TRACE_REQ_STATUS",
        link: "/pages/customer/sap-integration/history",
        parentId: 10,
      },
    ],
  },
];
export const View_Only: MenuItem[] = [
  {
    id: 1,
    label: "REQUESTS",
    icon: "bx-file",
    subItems: [
      {
        id: 101,
        label: "MILLING_CO",
        icon: "bx-dots-horizontal",
        parentId: 1,
        subItems: [
          {
            id: 10101,
            label: "COMPANY_NEW",
            link: "/pages/admin/request/list/new",
            parentId: 101,
          },
          {
            id: 10102,
            label: "JOIN_REQUESTS",
            link: "/pages/company/join-request/list/flour",
            parentId: 101,
          },
          {
            id: 10103,
            label: "JOIN_REQUESTS_feed",
            link: "/pages/company/join-request/list/feed",
            parentId: 101,
          },
          {
            id: 10104,
            label: "PENDING_REQS",
            link: "/pages/admin/request/list/company-pending",
            parentId: 101,
          },
          {
            id: 10105,
            label: "APPROVED_REQS",
            link: "/pages/admin/request/list/approved",
            parentId: 103,
          },
          {
            id: 10106,
            label: "REJECTED_REQS",
            link: "/pages/admin/request/list/company-rejected",
            parentId: 101,
          },
        ],
      },
      {
        id: 102,
        label: "CUSTOMER_REQUESTS",
        icon: "bx-dots-horizontal",
        parentId: 2,
        subItems: [
          {
            id: 10201,
            label: "INCREASE_REQUESTS",
            link: "/pages/customer/increase-amount/list/flour",
            parentId: 102,
          },
          {
            id: 10202,
            label: "TEMP_REQUESTS",
            link: "/pages/customer/temporary-product-manager-list",
            parentId: 102,
          },
          {
            id: 10203,
            label: "CHANGE_INFO_REQS",
            link: "/pages/customer/edit_profile/list",
            parentId: 102,
          },
          {
            id: 10204,
            label: "CHANGE_CATEGORY_REQS",
            link: "/pages/customer/change_category/list",
            parentId: 102,
          },
          {
            id: 10205,
            label: "CHANGE_PRODUCTS_REQS",
            link: "/pages/customer/edit_product/list",
            parentId: 102,
          },
          {
            id: 10206,
            label: "DISTRIBUTER_T_DISTRIBUTER",
            link: "/pages/customer/change-distributer-list",
            parentId: 102,
          },
          {
            id: 10207,
            label: "BRANCH_T_BRANCH",
            link: "/pages/customer/branch-transfer/list",
            parentId: 102,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    label: "SAGO",
    icon: "bx-cube",
    subItems: [
      {
        id: 201,
        label: "SAGO_APPROVED",
        link: "/pages/admin/request/list/approved",
        parentId: 2,
      },
      {
        id: 201,
        label: "SAGO_SENT",
        link: "/pages/admin/request/list/sago-sent",
        parentId: 2,
      },
      {
        id: 203,
        label: "SAGO_PENDING",
        link: "/pages/admin/request/list/sago-pending",
        parentId: 2,
      },
      {
        id: 204,
        label: "COMPANY_TRANSFERS",
        link: "/pages/admin/request/list/company-transfer",
        parentId: 2,
      },
      {
        id: 205,
        label: "SAGO_REJECTED",
        link: "/pages/admin/request/list/sago-rejected",
        parentId: 2,
      },
    ],
  },
  {
    id: 3,
    label: "CUSTOMER_SEARCH",
    icon: "bx-group",
    subItems: [
      {
        id: 301,
        label: "SEARCH_CUSTOMERS",
        link: "/pages/admin/customer-search/flour",
        parentId: 3,
      },
      {
        id: 302,
        label: "FEED_SEARCH_CUSTOMERS",
        link: "/pages/admin/customer-search/feed",
        parentId: 3,
      },
    ],
  },
  // {
  //   id: 4,
  //   label: "USERS",
  //   icon: "bx-user",
  //   subItems: [
  //     {
  //       id: 401,
  //       label: "USERS_LIST",
  //       link: "/pages/admin/user/list",
  //       parentId: 4,
  //     },
  //     {
  //       id: 402,
  //       label: "USERS_ADD",
  //       link: "/pages/admin/user/add",
  //       parentId: 4,
  //     },
  //   ],
  // },
  // {
  //   id: 5,
  //   label: "SETTINGS",
  //   icon: "bx-cog",
  //   subItems: [
  //     {
  //       id: 501,
  //       label: "SETTINGS_ACTIVITIES",
  //       link: "/pages/admin/settings/activity_type/list",
  //       parentId: 5,
  //     },
  //     {
  //       id: 501,
  //       label: "SETTINGS_PRODUCTS",
  //       link: "/pages/admin/settings/products/list",
  //       parentId: 5,
  //     },
  //   ],
  // },
  {
    id: 6,
    label: "GFSA_INSTITUTE",
    icon: "bxs-buildings",
    subItems: [
      {
        id: 601,
        label: "DELETED_INQUIRY",
        link: "/pages/company/sago-removed-clients",
        parentId: 6,
      },
      {
        id: 602,
        label: "THOUSAND_BAGS",
        link: "/pages/customer/more-than-thousand-bag/list",
        parentId: 4,
      },
    ],
  },
  {
    id: 7,
    label: "REPORTS",
    icon: "bxs-report",
    subItems: [
      {
        id: 701,
        label: "PRODUCTS_HISTORY",
        link: "/pages/admin/reports/product-history",
        parentId: 7,
      },
      {
        id: 702,
        label: "TEMP_AMOUNT_HISTORY",
        link: "/pages/admin/reports/temp-amount-history",
        parentId: 7,
      },
    ],
  },
  // {
  //   id: 8,
  //   label: "UPLOAD_CUSTOMERS",
  //   icon: "bxs-cloud-upload",
  //   subItems: [
  //     {
  //       id: 801,
  //       label: "UPLOAD_CUSTOMERS_FLOUR",
  //       link: "/pages/admin/upload/flour/customers",
  //       parentId: 8,
  //     },
  //     {
  //       id: 802,
  //       label: "UPLOAD_FLOUR_BENEFICIARY",
  //       link: "/pages/admin/upload/flour/beneficiary",
  //       parentId: 8,
  //     },
  //     {
  //       id: 803,
  //       label: "UPLOAD_CUSTOMERS_FEED",
  //       link: "/pages/admin/upload/feed/customers",
  //       parentId: 8,
  //     },
  //   ],
  // },
  // {
  //   id: 9,
  //   label: "UPLOAD_CUSTOMER_PRODUCTS",
  //   icon: "bx-upload",
  //   subItems: [
  //     {
  //       id: 901,
  //       label: "DOWNLOAD_MODEL",
  //       link: "/https://crm.firstmills.com/milling/media/templates/ApprovedCustomerProductTemplate.xlsx",
  //       parentId: 9,
  //     },
  //     {
  //       id: 902,
  //       label: "FLOUR_PRODUCTS",
  //       link: "/pages/admin/upload/flour/products",
  //       parentId: 9,
  //     },
  //     {
  //       id: 903,
  //       label: "FEED_PRODUCTS",
  //       link: "/pages/admin/upload/feed/products",
  //       parentId: 9,
  //     },
  //   ],
  // },
];

export const committee_Menu: MenuItem[] = [
  {
    id: 1,
    label: "REQUESTS",
    icon: "bx-file",
    subItems: [
      {
        id: 101,
        label: "MILLING_CO",
        icon: "bx-dots-horizontal",
        parentId: 1,
        subItems: [
          {
            id: 10101,
            label: "COMPANY_NEW",
            link: "/pages/admin/request/list/new",
            parentId: 101,
          },
          {
            id: 10102,
            label: "JOIN_REQUESTS",
            link: "/pages/company/join-request/list/flour",
            parentId: 101,
          },
          {
            id: 10103,
            label: "JOIN_REQUESTS_feed",
            link: "/pages/company/join-request/list/feed",
            parentId: 101,
          },
          {
            id: 10104,
            label: "PENDING_REQS",
            link: "/pages/admin/request/list/company-pending",
            parentId: 101,
          },
          {
            id: 10105,
            label: "APPROVED_REQS",
            link: "/pages/admin/request/list/approved",
            parentId: 103,
          },
          {
            id: 10106,
            label: "REJECTED_REQS",
            link: "/pages/admin/request/list/company-rejected",
            parentId: 101,
          },
        ],
      },
      {
        id: 102,
        label: "CUSTOMER_REQUESTS",
        icon: "bx-dots-horizontal",
        parentId: 2,
        subItems: [
          {
            id: 10201,
            label: "INCREASE_REQUESTS",
            link: "/pages/customer/increase-amount/list/flour",
            parentId: 102,
          },
          {
            id: 10202,
            label: "TEMP_REQUESTS",
            link: "/pages/customer/temporary-product-manager-list",
            parentId: 102,
          },
          {
            id: 10203,
            label: "CHANGE_INFO_REQS",
            link: "/pages/customer/edit_profile/list",
            parentId: 102,
          },
          {
            id: 10204,
            label: "CHANGE_CATEGORY_REQS",
            link: "/pages/customer/change_category/list",
            parentId: 102,
          },
          {
            id: 10205,
            label: "CHANGE_PRODUCTS_REQS",
            link: "/pages/customer/edit_product/list",
            parentId: 102,
          },
          {
            id: 10206,
            label: "DISTRIBUTER_T_DISTRIBUTER",
            link: "/pages/customer/change-distributer-list",
            parentId: 102,
          },
          {
            id: 10207,
            label: "BRANCH_T_BRANCH",
            link: "/pages/customer/branch-transfer/list",
            parentId: 102,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    label: "SAGO",
    icon: "bx-cube",
    subItems: [
      {
        id: 201,
        label: "SAGO_APPROVED",
        link: "/pages/admin/request/list/approved",
        parentId: 2,
      },
      {
        id: 201,
        label: "SAGO_SENT",
        link: "/pages/admin/request/list/sago-sent",
        parentId: 2,
      },
      {
        id: 203,
        label: "SAGO_PENDING",
        link: "/pages/admin/request/list/sago-pending",
        parentId: 2,
      },
      {
        id: 204,
        label: "COMPANY_TRANSFERS",
        link: "/pages/admin/request/list/company-transfer",
        parentId: 2,
      },
      {
        id: 205,
        label: "SAGO_REJECTED",
        link: "/pages/admin/request/list/sago-rejected",
        parentId: 2,
      },
    ],
  },
  {
    id: 3,
    label: "CUSTOMER_SEARCH",
    icon: "bx-group",
    subItems: [
      {
        id: 301,
        label: "SEARCH_CUSTOMERS",
        link: "/pages/admin/customer-search/flour",
        parentId: 3,
      },
      {
        id: 302,
        label: "FEED_SEARCH_CUSTOMERS",
        link: "/pages/admin/customer-search/feed",
        parentId: 3,
      },
    ],
  },
];

export const nonAdmin_Menu: MenuItem[] = [
  {
    id: 1,
    label: "REQUESTS",
    icon: "bx-file",
    subItems: [
      {
        id: 101,
        label: "MILLING_CO",
        icon: "bx-dots-horizontal",
        parentId: 1,
        subItems: [
          {
            id: 10101,
            label: "COMPANY_NEW",
            link: "/pages/admin/request/list/new",
            parentId: 101,
          },
          {
            id: 10102,
            label: "JOIN_REQUESTS",
            link: "/pages/company/join-request/list/flour",
            parentId: 101,
          },
          {
            id: 10103,
            label: "JOIN_REQUESTS_feed",
            link: "/pages/company/join-request/list/feed",
            parentId: 101,
          },
          {
            id: 10104,
            label: "PENDING_REQS",
            link: "/pages/admin/request/list/company-pending",
            parentId: 101,
          },
          {
            id: 10105,
            label: "APPROVED_REQS",
            link: "/pages/admin/request/list/approved",
            parentId: 103,
          },
          {
            id: 10106,
            label: "REJECTED_REQS",
            link: "/pages/admin/request/list/company-rejected",
            parentId: 101,
          },
        ],
      },
      {
        id: 102,
        label: "CUSTOMER_REQUESTS",
        icon: "bx-dots-horizontal",
        parentId: 2,
        subItems: [
          {
            id: 10201,
            label: "INCREASE_REQUESTS",
            link: "/pages/customer/increase-amount/list/flour",
            parentId: 102,
          },
          {
            id: 10202,
            label: "TEMP_REQUESTS",
            link: "/pages/customer/temporary-product-manager-list",
            parentId: 102,
          },
          {
            id: 10203,
            label: "CHANGE_INFO_REQS",
            link: "/pages/customer/edit_profile/list",
            parentId: 102,
          },
          {
            id: 10204,
            label: "CHANGE_CATEGORY_REQS",
            link: "/pages/customer/change_category/list",
            parentId: 102,
          },
          {
            id: 10205,
            label: "CHANGE_PRODUCTS_REQS",
            link: "/pages/customer/edit_product/list",
            parentId: 102,
          },
          {
            id: 10206,
            label: "DISTRIBUTER_T_DISTRIBUTER",
            link: "/pages/customer/change-distributer-list",
            parentId: 102,
          },
          {
            id: 10207,
            label: "BRANCH_T_BRANCH",
            link: "/pages/customer/branch-transfer/list",
            parentId: 102,
          },
          {
            id: 10208,
            label: "CUSTOMER_SEASONAL_REQUESTS",
            link: "/pages/customer/all-seasonal-requests-list",
            parentId: 102,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    label: "SAGO",
    icon: "bx-cube",
    subItems: [
      {
        id: 201,
        label: "SAGO_APPROVED",
        link: "/pages/admin/request/list/approved",
        parentId: 2,
      },
      {
        id: 201,
        label: "SAGO_SENT",
        link: "/pages/admin/request/list/sago-sent",
        parentId: 2,
      },
      {
        id: 203,
        label: "SAGO_PENDING",
        link: "/pages/admin/request/list/sago-pending",
        parentId: 2,
      },
      {
        id: 204,
        label: "COMPANY_TRANSFERS",
        link: "/pages/admin/request/list/company-transfer",
        parentId: 2,
      },
      {
        id: 205,
        label: "SAGO_REJECTED",
        link: "/pages/admin/request/list/sago-rejected",
        parentId: 2,
      },
    ],
  },
  {
    id: 3,
    label: "CUSTOMER_SEARCH",
    icon: "bx-group",
    subItems: [
      {
        id: 301,
        label: "SEARCH_CUSTOMERS",
        link: "/pages/admin/customer-search/flour",
        parentId: 3,
      },
      {
        id: 302,
        label: "FEED_SEARCH_CUSTOMERS",
        link: "/pages/admin/customer-search/feed",
        parentId: 3,
      },
    ],
  },
  {
    id: 4,
    label: "GFSA_INSTITUTE",
    icon: "bxs-buildings",
    subItems: [
      {
        id: 401,
        label: "DELETED_INQUIRY",
        link: "/pages/company/sago-removed-clients",
        parentId: 4,
      },
      {
        id: 602,
        label: "THOUSAND_BAGS",
        link: "/pages/customer/more-than-thousand-bag/list",
        parentId: 4,
      },
    ],
  },
  {
    id: 5,
    label: "REPORTS",
    icon: "bxs-report",
    subItems: [
      {
        id: 501,
        label: "PRODUCTS_HISTORY",
        link: "/pages/admin/reports/product-history",
        parentId: 5,
      },
      {
        id: 502,
        label: "TEMP_AMOUNT_HISTORY",
        link: "/pages/admin/reports/temp-amount-history",
        parentId: 5,
      },
    ],
  },
];

export const distributer_Menu: MenuItem[] = [
  {
    id: 1,
    label: "BENEFICIARIES",
    icon: "bxs-group",
    subItems: [
      {
        id: 101,
        label: "BENEFICIARIES_LIST",
        link: "/pages/customer/beneficiary/list",
        parentId: 1,
      },
      {
        id: 102,
        label: "BENEFICIARIES_ADD",
        link: "/pages/customer/beneficiary/add",
        parentId: 1,
      },
    ],
  },
  {
    id: 2,
    label: "CHANGE_INFO_REQ",
    icon: "bxs-file",
    link: "/pages/customer/edit_profile/create",
  },
  {
    id: 3,
    label: "TEMP_REQ",
    icon: "bxs-timer",
    link: "/pages/customer/temporary-product-list",
  },
];

export const direct_menu: MenuItem[] = [
  {
    id: 1,
    label: "INCREASE_REQ",
    icon: "bx-chevrons-up",
    link: "/pages/customer/increase-amount/create",
  },
  {
    id: 2,
    label: "TEMP_REQ",
    icon: "bxs-timer",
    link: "/pages/customer/temporary-product-list",
  },
  {
    id: 3,
    label: "CHANGE_INFO_REQ",
    icon: "bxs-file",
    link: "/pages/customer/edit_profile/create",
  },
  {
    id: 4,
    label: "PRODUCTS_EDIT_REQ",
    icon: "bx-package",
    link: "/pages/customer/edit_product/create",
  },
  {
    id: 5,
    label: "CATEGORY_CHANGE_REQ",
    icon: "bxs-analyse",
    link: "/pages/customer/change_category/create",
  },
  {
    id: 6,
    label: "BRANCH_CHANGE_REQ",
    icon: "bx-git-branch",
    link: "/pages/customer/branch-transfer/create",
  },
  {
    id: 7,
    label: "SEASONAL_CUSTOMER_CREATE",
    icon: "bx-calendar-event",
    link: "/pages/customer/seasonal-list",
  },
];

export const beneficiary_menu: MenuItem[] = [
  {
    id: 1,
    label: "INCREASE_REQ",
    icon: "bx-chevrons-up",
    link: "/pages/customer/increase-amount/create",
  },
  {
    id: 2,
    label: "TEMP_REQ",
    icon: "bxs-timer",
    link: "/pages/customer/temporary-product-list",
  },
  {
    id: 3,
    label: "CHANGE_INFO_REQ",
    icon: "bxs-file",
    link: "/pages/customer/edit_profile/create",
  },
  {
    id: 4,
    label: "PRODUCTS_EDIT_REQ",
    icon: "bx-package",
    link: "/pages/customer/edit_product/create",
  },
  {
    id: 5,
    label: "CATEGORY_CHANGE_REQ",
    icon: "bxs-analyse",
    link: "/pages/customer/change_category/create",
  },
  {
    id: 6,
    label: "DISTRIBUTER_T_DISTRIBUTER",
    icon: "bx-git-branch",
    link: "/pages/customer/change-distributor",
  },
  {
    id: 7,
    label: "BRANCH_CHANGE_REQ",
    icon: "bx-git-branch",
    link: "/pages/customer/branch-transfer/create",
  },
];
