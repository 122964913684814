import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { FilterModelDto } from "src/app/pages/company/company.utility";
import { ResponseDto } from "../interfaces/api-response";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private HTTP: HttpClient) {}

  getDataFromServer<TResponse extends object = ResponseDto>(
    base_url: string,
    pagination?: { page: number; size: number },
    filter?: any,
    response_type?: any
  ) {
    this.removeInvalidValues(filter);
    return this.HTTP.get<TResponse>(base_url, {
      params: { ...pagination, ...filter },
      responseType: response_type,
    });
  }

  getDataFromServerById<TResponse extends object = ResponseDto>(
    base_url: string,
    id: number,
    pagination?: { page: number; size: number }
  ) {
    return this.HTTP.get<TResponse>(base_url + id, {
      params: { ...pagination },
    });
  }

  sendDataToServer<
    TBody extends object,
    TResponse extends object = ResponseDto
  >(base_url: string, model: TBody, id?: string) {
    return this.HTTP.post<TResponse>(id ? base_url + id : base_url, model);
  }

  updateDataOnServer<
    TMethod extends "put" | "patch",
    TBody extends object,
    TResponse extends object = ResponseDto
  >(method: TMethod, base_url: string, model: TBody, id?: string) {
    return this.HTTP[method]<TResponse>(id ? base_url + id : base_url, model);
  }

  deleteDataOnServer<TResponse extends object = ResponseDto>(
    base_url: string,
    id?: string,
    list?: { [x: string]: number[] } | number[]
  ) {
    return this.HTTP.delete<TResponse>(base_url + id, { body: list });
  }

  // Utilities
  private readonly removeInvalidValues = (FilterModel: FilterModelDto) => {
    let key: keyof FilterModelDto;
    for (key in FilterModel) {
      if (
        FilterModel[key] === null ||
        FilterModel[key] === undefined ||
        FilterModel[key] === ""
      )
        delete FilterModel[key];
    }
  };
}
