<app-custom-header header_title="{{ title }}"></app-custom-header>

<div class="mx-2 d-flex justify-content-end">
  <button
    *ngIf="activeCustomerType == 'flour'"
    [matMenuTriggerFor]="download"
    class="bg-success text-white"
    mat-button
  >
    <span class="px-2">{{ "DOWNLOAD_CLIENTS" | translate }}</span>
    <mat-icon>cloud_download</mat-icon>
  </button>
</div>

<div class="mx-2">
  <app-table-filter
    (filterModel)="newFilterModel($event)"
    [hasData]="hasData$"
    [isRequest]="false"
    [isFeed]="activeCustomerType == 'feed'"
  >
  </app-table-filter>
</div>
<div
  class="mat-elevation-z1 rounded-3 mx-2"
  style="max-height: 30rem; min-height: 20rem; overflow: auto"
>
  <table class="w-100" mat-table [dataSource]="clientsDataSource" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "ORG_NAME" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="customer_category_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "CLIENT_CATEGORY" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.customer_category_name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="branch_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "BRANCH" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.branch_name }}</td>
    </ng-container>

    <ng-container matColumnDef="sap_customer_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "SAP_NUM" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.sap_customer_id }}</td>
    </ng-container>

    <ng-container matColumnDef="sago_customer_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "SAGO_NUM" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.sago_customer_id }}</td>
    </ng-container>

    <ng-container matColumnDef="customer_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "CLIENT_PORTAL_NUM" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.customer_number }}</td>
    </ng-container>

    <ng-container matColumnDef="is_deleted">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "CLIENT_STATUS" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <span [ngClass]="{ inactive: row.is_deleted, active: !row.is_deleted }">
          {{
            row.is_deleted ? ("INACTIVE" | translate) : ("ACTIVE" | translate)
          }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        {{ "ACTIONS" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            [routerLink]="['/pages/admin/client-view', row.id]"
          >
            <mat-icon>assignment_ind</mat-icon>
            <span> {{ "CLIENT_DATA" | translate }}</span>
          </button>
          <button
            *ngIf="userType != 'view_only'"
            mat-menu-item
            [routerLink]="['/pages/admin/correct-client-data', row.id]"
          >
            <mat-icon>edit</mat-icon>
            <span> {{ "CORRECT_CLIENT_DATA" | translate }}</span>
          </button>
          <button
            *ngIf="row.is_deleted && userType != 'view_only'"
            mat-menu-item
            (click)="activateClient(row.id)"
          >
            <mat-icon>task_alt</mat-icon>
            <span>{{ "ACTIVATE_CLIENT" | translate }}</span>
          </button>
          <button
            *ngIf="!row.is_deleted && userType != 'view_only'"
            mat-menu-item
            (click)="deActivateClient(row.id)"
          >
            <mat-icon>block</mat-icon>
            <span>{{ "DEACTIVATE_CLIENT" | translate }}</span>
          </button>
          <button
            mat-menu-item
            [matMenuTriggerFor]="requests"
            *ngIf="
              userType != 'view_only' &&
              activeCustomerType != 'feed' &&
              row.gfsa_service_enabled
            "
          >
            <mat-icon>file_copy</mat-icon>
            <span>{{ "CUSTOMER_REQUESTS" | translate }}</span>
          </button>
        </mat-menu>

        <mat-menu #requests="matMenu">
          <button
            mat-menu-item
            [routerLink]="'/pages/customer/edit_profile/create'"
            [queryParams]="{ customer: row.id }"
          >
            <mat-icon>edit_road</mat-icon>
            <span>{{ "CHANGE_INFO_REQ" | translate }}</span>
          </button>
          <button
            [disabled]="
              row.customer_category == CATEGORY.MULTI ||
              row.customer_category == CATEGORY.DISTRIBUTER
            "
            mat-menu-item
            [routerLink]="'/pages/customer/edit_product/create'"
            [queryParams]="{ customer: row.id }"
          >
            <mat-icon>shopping_cart</mat-icon>
            <span>{{ "PRODUCTS_EDIT_REQ" | translate }}</span>
          </button>
          <button
            [disabled]="
              row.customer_category == CATEGORY.MULTI ||
              row.customer_category == CATEGORY.DISTRIBUTER
            "
            [routerLink]="'/pages/customer/change_category/create'"
            [queryParams]="{
              customer: row.id,
              category: row.customer_category,
              branch: row.branch
            }"
            mat-menu-item
          >
            <mat-icon>change_circle</mat-icon>
            <span>{{ "CATEGORY_CHANGE_REQ" | translate }}</span>
          </button>
          <button
            [disabled]="row.customer_category != CATEGORY.BENEFICIARY"
            [routerLink]="'/pages/customer/change-distributor'"
            [queryParams]="{
              customer: row.id,
              category: row.customer_category
            }"
            mat-menu-item
          >
            <mat-icon>loop</mat-icon>
            <span>{{ "DISTRIBUTER_T_DISTRIBUTER" | translate }}</span>
          </button>
          <button
            [routerLink]="'/pages/customer/branch-transfer/create'"
            [queryParams]="{ customer: row.id }"
            mat-menu-item
          >
            <mat-icon>alt_route</mat-icon>
            <span>{{ "BRANCH_CHANGE_REQ" | translate }}</span>
          </button>
          <button
            [disabled]="row.customer_category == CATEGORY.MULTI"
            [routerLink]="'/pages/customer/temporary-product-request'"
            [queryParams]="{
              customer: row.id,
              category: row.customer_category
            }"
            mat-menu-item
          >
            <mat-icon>access_time</mat-icon>
            <span>{{ "TEMP_INCREASE_REQS" | translate }}</span>
          </button>
          <button
            [disabled]="
              row.customer_category == CATEGORY.MULTI ||
              row.customer_category == CATEGORY.DISTRIBUTER
            "
            [routerLink]="'/pages/customer/increase-amount/create'"
            [queryParams]="{ customer: row.id }"
            mat-menu-item
          >
            <mat-icon>trending_up</mat-icon>
            <span>{{ "INCREASE_REQ" | translate }}</span>
          </button>
          <button
            [disabled]="row.customer_category != 1"
            routerLink="/pages/customer/seasonal-customers/create"
            [queryParams]="{ id: row.id }"
            mat-menu-item
          >
            <mat-icon>trending_up</mat-icon>
            <span>{{ "SEASONAL_CUSTOMER_CREATE" | translate }}</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr
      *matHeaderRowDef="displayedColumns; sticky: true"
      class="mat-elevation-z1"
      mat-header-row
    ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell text-center" colspan="8">
        {{ "NO_DATA" | translate }}
      </td>
    </tr>
  </table>
</div>
<mat-paginator
  class="mb-5 rounded-3 mx-2 mt-2 mat-elevation-z1"
  #paginator
  [pageIndex]="currentPage"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="pageChange($event)"
  showFirstLastButtons
>
</mat-paginator>

<!-- Download menu -->
<mat-menu #download="matMenu">
  <button
    (click)="downloadExcelSheetTemplate(ApprovedClientsExcelData)"
    mat-menu-item
  >
    <mat-icon>vertical_align_bottom</mat-icon>
    <span class="ps-3">{{ "PORTAL_CLIENTS" | translate }}</span>
  </button>
  <button (click)="downloadCustomersProducts()" mat-menu-item>
    <mat-icon>vertical_align_bottom</mat-icon>
    <span class="ps-3">{{ "PORTAL_PRODUCTS" | translate }}</span>
  </button>
  <button (click)="downloadSagoCustomers()" mat-menu-item>
    <mat-icon>vertical_align_bottom</mat-icon>
    <span class="ps-3">{{ "SAGO_CLIENTS" | translate }}</span>
  </button>
</mat-menu>
