<footer class="footer position-static">
  <div class="row text-center align-items-center w-75 mx-auto">
    <div class="col-sm-6 my-1">
      {{ year }} © {{ environment.title | translate }}
    </div>
    <div class="col-sm-6 my-1">
      Design & Develop by <a href="https://www.arkleap.com/">Arkleap</a>
    </div>
  </div>
</footer>
