export const MCBranches = {
  MC: 4,
  MC4Title: "شركة المطاحن الرابعة MC4",
  MC4BaseURL: "https://crmportal.mc4.com.sa/milling/api/", // production server
  // MC4BaseURL: "http://192.168.30.188/milling/api/", // test server
  // MC4BaseURL: "http://192.168.1.41:8004/mc4/api/", // local server
};

export const environment = {
  production: true,
  local_storage_private_key: "milling4EncryptionKey",

  // MC4 downloading excel sheet urls
  customerTemplateUrl:
    "https://crm.firstmills.com/milling/media/templates/CustomersTemplate.xlsx",
  BeneficiaryTemplateUrl:
    "https://crm.firstmills.com/milling/media/templates/BeneficiariesTemplate.xlsx",
  customerProductTemplateUrl:
    "https://crm.firstmills.com/milling/media/templates/ApprovedCustomerProductTemplate.xlsx",

  baseUrl: MCBranches.MC4BaseURL,
  title: MCBranches.MC4Title,

  google_maps_key: "AIzaSyBqtphDqvKbaGEjk-BNVmXZE4YXe_11Vi4",
};
